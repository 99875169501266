<template>
  <div>
    <b-row class="d-flex justify-content-center align-items-center">
      <b-col
        md="4"
        class="d-flex justify-content-center align-items-center mb-0 pt-0"
      >
        <div class="text-center">
          <img src="@/assets/images/ability_test/pa.png" alt="">
          <h2 class="text-light my-5">Personality assessment</h2>
          <p>This is a personality assessment that focuses on your strengths, the things that you’re naturally inclined to be good at, and gives you the tools and coaching to be even better.</p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2"></b-col>
      <b-col md="8">
        <b-row class="mt-2">
          <b-col md="4">
            <div class="box-wrapper text-center">
              <img src="@/assets/images/ability_test/clock.png" alt="">
              <h4 class="text-light mt-2">The test will take 35 minutes</h4>
            </div>
          </b-col>
          <b-col md="4">
            <div class="box-wrapper text-center">
              <img src="@/assets/images/ability_test/outline.png" alt="">
              <h4 class="text-light mt-2">Find somewhere quiet where <br> you won’t be disturbed</h4>
            </div>
          </b-col>
          <b-col md="4">
            <div class="box-wrapper text-center">
              <img src="@/assets/images/ability_test/eye.png" alt="">
              <h4 class="text-light mt-2">For the most accurate feedback, <br> focus and answer honestly</h4>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
</style>
