<template>
  <div>
    <b-row class="d-flex justify-content-center align-items-center">
      <b-col
        md="4"
        class="d-flex justify-content-center align-items-center mb-0 pt-0"
      >
        <div class="text-center">
          <img src="@/assets/images/ability_test/pa.png" alt="">
          <h2 class="text-light my-5">Well done for completing the personality assessment!</h2>
          <p>Now you’ll receive personalised feedback that you can use to develop your strengths.</p>
        </div>  
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
</style>
