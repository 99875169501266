<template>
  <div class="ability_test text-light">
    <div class="w-100 d-flex justify-content-end">
      <img src="@/assets/images/logo/logo.png" alt="" class="logo" style="width: 150px;">
    </div>
    <Overview v-if="navigationComp == 1"/>
    <Instruction v-if="navigationComp == 2"/>
    <Assessment1 v-if="navigationComp == 3"/>
    <Assessment2 v-if="navigationComp == 4"/>
    <Outro v-if="navigationComp == 5"/>
    <Feedback v-if="navigationComp == 6"/>
    <b-row class="w-100 d-flex justify-content-center align-items-center ml-0">
      <button class="btn btn-primary rounded-pill px-5 mt-3" @click="next()" v-if="navigationComp == 1">
        Start
      </button>
      <button class="btn btn-primary rounded-pill px-5 mt-3" @click="next()" v-else-if="navigationComp == 5">
        Read my feedback
      </button>
      <button class="btn btn-primary rounded-pill px-5 mt-3" @click="next()" v-else-if="navigationComp < 6">
        Next
      </button>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Overview from '@/views/pages/ability_test/Overview.vue'
import Instruction from '@/views/pages/ability_test/Instruction'
import Assessment1 from '@/views/pages/ability_test/Assessment1'
import Assessment2 from '@/views/pages/ability_test/Assessment2'
import Outro from '@/views/pages/ability_test/Outro'
import Feedback from '@/views/pages/ability_test/Feedback'

import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    Overview,
    Instruction,
    Assessment1,
    Assessment2,
    Outro,
    Feedback,
  },
  data() {
    return {
      navigationComp: 1
    }
  },
  computed: {
  },
  methods: {
    next(){
      this.navigationComp = this.navigationComp + 1
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
</style>
