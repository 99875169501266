<template>
 <div>
  <div class="w-100 text-center" style="margin-top: -30px">
    <!-- style="position: absolute; top: 20px; left: 47%" -->
    <img src="@/assets/images/ability_test/page_number1.png" alt="" >
  </div>
  <b-row class="pt-3">
    <b-col md="2"></b-col>
    <b-col md="8">

      <h4 class="text-center text-light font-weight-light px-5 mb-3">In the first section, you are going to see some image-based questions. To answer each question, you will need to select the image that best represents you. There are no correct or incorrect answers, please answer honestly.</h4>

      <div class="text-center">
          <img src="@/assets/images/ability_test/laptop.png" alt="">
      </div>

    </b-col>
    <b-col md="2"></b-col>
  </b-row>

 </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
</style>
