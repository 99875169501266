<template>
 <div>
  <div class="w-100 text-center" style="margin-top: -30px">
    <!-- style="position: absolute; top: 20px; left: 47%" -->
    <img src="@/assets/images/ability_test/page_number2.png" alt="" >
  </div>
  <b-row class="pt-3">
    <b-col md="2"></b-col>
    <b-col md="8">
      <h4 class="text-center text-light font-weight-light px-5 mb-3">Which is more like you ?</h4>
    </b-col>
    <b-col md="2"></b-col>
  </b-row>
  <b-row class="w-100 banner py-5">
    <b-col md="2"></b-col>
    <b-col md="2"></b-col>
    <b-col md="2"> 
      <div :class="answer == 'cat' ? 'text-center cursor-pointer selected' : 'text-center cursor-pointer'" @click="select('cat')">
        <img src="@/assets/images/ability_test/cat.png" alt="" width="100%">
      </div>
    </b-col>
    <b-col md="2"> 
      <div :class="answer == 'eagle' ? 'text-center cursor-pointer selected' : 'text-center cursor-pointer'" @click="select('eagle')">
        <img src="@/assets/images/ability_test/eagle.png" alt="" width="100%">
      </div>
    </b-col>
    <b-col md="2"></b-col>
    <b-col md="2"> </b-col>
  </b-row>
 </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      answer: null
    }
  },
  computed: {
  },
  methods: {
    select(data){
      this.answer = data
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
</style>
<style scoped>
.banner{
  background: url('../../../assets/images/ability_test/banner.png');
}
.selected{
  border: 5px solid #E84185 !important;
  border-radius: 15px;
  box-shadow: 0 1rem 3rem #E84185 !important;
  /* box-shadow: #E84185 ; */
}
</style>
