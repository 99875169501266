<template>
  <div>
    <b-row class="text-center">
      <b-col md="3">
      </b-col>
      <b-col md="6">
        <b-row class="d-flex justify-content-between pb-5">
          <h2 class="text-light">Your feedback</h2>
          <button class="btn btn-primary rounded-pill">Continue to dashboard</button>
        </b-row>
          <h4 class="text-light d-block text-left mb-3">You are: </h4>
        <b-row>
          <div class="d-block">
            <b-tabs vertical>
              <b-tab class="pt-5">
                <template #title>
                  <span class="d-none d-sm-inline">Adaptable </span>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                </template>
              </b-tab>
              <b-tab title="dddddd">
                aaaaaaaaaaaaaaaasAD
              </b-tab>
              <b-tab title="ccccc">
                vvvvvvvvvvvvvvvvv
              </b-tab>
            </b-tabs>
          </div>
        </b-row>
      </b-col>
      <b-col md="3">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
  BTabs,
  BTab
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
</style>
<style scoped lang="css">
/* /deep/.nav-item {
    border-radius: 10px !important;
    position: relative !important;
    background-color: #fff !important;
    margin-bottom: 15px !important;
    padding: 7px 20px 7px 20px !important;
}
/deep/.nav-pills .nav-link, .nav-tabs .nav-link{
  justify-content: space-between !important;
} */
</style>
